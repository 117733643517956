
//Color

$primary-color: #1D946B;
$primary-light-color: #EDF7F3;
$gray: #2F4D42;
$white: #fff;


//Font size
$fontsize-14: 14px;
$fontsize-16: 16px;
$fontsize-18: 18px;
$fontsize-26: 26px;


//width
$sidebar-width: 250px;