@import "src/variable";
@import "@yaireo/tagify/dist/tagify.css";
//Common Css
body {
  font-family: "Montserrat", sans-serif;
}
.loader {
  height: 100vh;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2f4d4233;
  border-radius: 25px;
}




/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
%input-box,
%select {
  background: #1d946b0f;
  border-radius: 5px;
  height: 60px;
  border: 2px solid transparent;
  outline: none;
  padding: 0 20px;
  font-size: $fontsize-16;
  font-weight: 500;
  color: $gray;
  transition: 0.3s;
  &:focus {
    box-shadow: none;
    transition: 0.3s;
    border: 2px solid $primary-color;
  }
  &:hover {
    border: 2px solid $primary-color;
    transition: 0.3s;
  }
  &::placeholder {
    color: #2f4d4240;
    font-weight: 500;
  }
}

%select {
  background-image: linear-gradient(45deg, transparent 50%, #2f4d42 60%),
    linear-gradient(135deg, #2f4d42 40%, transparent 50%) !important;
  background-position: calc(100% - 25px) 25px, calc(100% - 15px) 25px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

%input-label {
  font-size: $fontsize-14;
  line-height: 18px;
  color: $gray;
  font-weight: 600;
  padding: 0 0 10px 0;
}

//Primary Button
%primary-btn {
  width: 100%;
  height: 60px;
  font-weight: 600;
  background: $primary-color;
  border-radius: 10px;
  border: 2px solid $primary-color;
  color: #fff;
  font-size: $fontsize-18;
  line-height: 22px;
  transition: 0.3s;
  &:hover {
    background: #fff;
    color: $primary-color;
    transition: 0.3s;
  }
}

//Checkbox css
%cust_checkbox {
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: $fontsize-18;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $gray;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $primary-color;
      &:after {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: 3px solid #1d946b1a;
    border-radius: 6px;
    background-color: $primary-light-color;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 1px;
      width: 8px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

//Radio button
%cust_radio {
  display: block;
  float: left;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: $fontsize-16;
  font-weight: 600;
  color: $gray;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      &:after {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    background-color: #fff;
    border-radius: 50%;
    border: 3px solid #1d946b66;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $primary-color;
    }
  }
}

//Table
%table {
  margin-bottom: 0;
  thead {
    box-shadow: 0px 1px 6px #0000001a;
    border-radius: 5px;
    
    th {
      height: 58px;
      background: #fff;
      font-size: 14px;
      font-weight: 600;
      color: $gray;
      vertical-align: middle;
      border: none;
      padding: 10px 5px;
      position: sticky;
      top: 0;
      z-index: 10;
      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 80px;
        text-align: center;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: $fontsize-14;
        font-weight: 600;
        color: #2f4c41b3;
        line-height: 18px;
        vertical-align: middle;
        padding: 16px 5px;
        border: none;
        &:first-child {
          text-align: center;
          width: 100px;
          min-width: 100px;
        }
        .cust_checkbox {
          @extend %cust_checkbox;
          width: 26px;
          height: 26px;
          margin-right: -10px;
        }
      }
      &:nth-of-type(odd) {
        --bs-table-accent-bg: #fff;
      }
      &:nth-of-type(even) {
        --bs-table-accent-bg: #f8f9f9;
      }
    }
    .alarmEdit {
      --bs-table-accent-bg: #a9d9c2 !important;
    }
  }
}

//Login - Signup - Forgot pass css

.auth-main-section {
  width: 100%;
  float: left;
  height: 100vh;
  .auth-box-right {
    width: calc(100% - 44%);
    float: left;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .auth-box-right-inner {
      width: 100%;
      max-width: 500px;
      margin: auto;
      padding-top: 15px;

      .app-logo {
        text-align: center;
      }
      .auth-title {
        margin-bottom: 50px;
        margin-top: 10px;
        width: 100%;
        float: left;
        h4 {
          margin: 0;
          text-align: center;
          font-size: 32px;
          font-weight: bold;
          color: $gray;
        }
        p {
          font-size: $fontsize-16;
          line-height: 19px;
          font-weight: 600;
          margin-top: 20px;
          margin-bottom: 0;
          padding: 0 100px;
          text-align: center;
          color: #2f4d424d;
        }
      }

      .form-row {
        margin-bottom: 20px;
        width: 100%;
        float: left;
        .form-control {
          @extend %input-box;
        }
        .col-form-label {
          @extend %input-label;
        }
        .forgot-link {
          font-size: $fontsize-18;
          font-weight: 600;
          float: right;
          text-decoration: none;
          color: $primary-color;
        }
        .cust_checkbox {
          @extend %cust_checkbox;
        }
        .auth-link {
          font-size: $fontsize-16;
          color: $gray;
          font-weight: 600;
          text-align: center;
          a {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
      .auth-btn-grp {
        margin-top: 30px;
        text-align: center;
        width: 100%;
        float: left;
        .primary-btn {
          @extend %primary-btn;
          max-width: 330px;
          margin-bottom: 15px;
        }
      }
    }
  }
  .auth-box-left {
    width: 44%;
    height: 100vh;
    // max-width: 600px;
    float: left;
    background-color: $primary-light-color;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      max-width: 467px;
      transition: all 0.5s;
    }
  }
}

//Sidebar Section
.sidebar-section {
  width: $sidebar-width;
  background: $primary-color;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);

  .sidebar-logo {
    font-size: $fontsize-26;
    font-weight: 800;
    color: $white;
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    cursor: pointer;
  }
  .sidebar-search-box {
    position: relative;
    padding: 0 15px;
    input {
      height: 30px;
      border: 2px solid #2f4d424f;
      border-radius: 4px;
      background: #fff;
      font-size: 12px;
      font-weight: 600;
      padding-right: 25px;
      padding-left: 10px;
      &::placeholder {
        color: #2f4d4259;
      }
    }
    .sidebar-search-box-icon {
      position: absolute;
      right: 25px;
      top: 2px;
      cursor: pointer;
    }
  }
  .sidebar-menu-title {
    font-size: $fontsize-16;
    color: $white;
    font-weight: bold;
    padding-left: 15px;
    margin: 15px 0;
  }
  .sidebar-menu {
    padding: 0px;
    list-style: none;
    width: 100%;
    float: left;
    height: calc(100% - 400px);
    margin-bottom: 0;
    overflow: auto;
    .nav-item {
      width: 100%;
      float: left;
      padding: 10px 15px;
      transition: 0.3s;
      cursor: pointer;
      background: #2f4d4233;
      a {
        font-size: $fontsize-14;
        line-height: 18px;
        width: 100%;
        float: left;
        color: $white;
        font-weight: 600;
        text-decoration: none;
        text-transform: capitalize;
        .sidebar-menu-arrow {
          float: right;
          width: 18px;
          transform: rotate(180deg);
        }
        .menu-icon {
          margin-right: 6px;
          float: left;
        }
        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: calc(100% - 40px);
          float: left;
          margin-bottom: 0;
        }
      }
      &.collapsed {
        background: $primary-color;
        a {
          .sidebar-menu-arrow {
            transform: rotate(0deg);
          }
        }
      }

      &:hover,
      &:focus,
      &.active {
        background: #2f4d4233;
        transition: 0.3s;
      }
      .sidebar-submenu {
        width: 100%;
        float: left;

        ul {
          list-style: none;
          width: 100%;
          float: left;
          padding-left: 25px;

          li {
            width: 100%;
            float: left;
            padding-top: 10px;
            a {
              font-size: 12px;
            }
            &.collapsed {
              a {
                .sidebar-menu-arrow {
                  transform: rotate(0deg);
                }
              }
            }
          }
        }
      }
    }
  }

  .sidebar-alarm-box {
    width: 100%;
    float: left;
    position: sticky;
    top: 100%;
    bottom: 0;
    ul {
      list-style: none;
      padding-left: 15px;
      margin-bottom: 10px;
      height: 170px;
      overflow: auto;
      li {
        margin-top: 15px;
        a {
          font-size: $fontsize-14;
          font-weight: 600;
          color: $white;
          text-decoration: none;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          float: left;
          img {
            margin-right: 6px;
            float: left;
          }
          span {
            width: calc(100% - 55px);
            float: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .trash-not-allow {
          }
          .trash-allow {
          }
        }
      }
    }
  }
}

//Header section
.sticky-top-custom {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 998;
}
.header-section {
  width: 100%;
  float: right;
  height: 50px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 10px 15px;
  .dropdown {
    .dropdown-toggle {
      font-size: $fontsize-14;
      font-weight: bold;
      color: $primary-color;
      text-transform: capitalize;
      .rounded-circle {
        border: 2px solid $primary-color;
      }
      &::after {
        display: none;
      }
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 130px;
      }
    }
    .dropdown-menu {
      padding: 0;
      border-radius: 6px;
      box-shadow: 0px 0px 16px #a3a3a329;
      border: none;
      margin-top: 10px !important;
      min-width: 180px;
      padding: 10px 0;
      li {
        a {
          font-size: $fontsize-14;
          font-weight: 500;
          color: $gray;
          line-height: 18px;
          padding: 10px 20px;
          transition: 0.3s;
          width: 100%;
          float: left;
          text-decoration: none;
          border-radius: 10px;
          &:hover,
          &.active {
            background: #fff;
            color: $primary-color;
            font-weight: 600;
            transition: 0.3s;
          }
        }
      }
    }
  }
}

//breadcrumb
.breadcrumb-box {
  width: 100%;
  float: left;
  display: flex;
  padding: 0 15px;
  justify-content: space-between;
  position: sticky;
  top: 50px;
  background: #fff;
  h4 {
    font-size: 20px;
    color: #01323e;
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    text-transform: capitalize;
  }
  .breadcrumb {
    height: 50px;
    margin-bottom: 0;
    align-items: center;
    .breadcrumb-item {
      a {
        color: $primary-color;
        font-size: 12px;
        font-weight: 600;
        text-decoration: none;
      }
      &.active {
        color: #2f4d424d;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}
//Right Section
.right-section {
  width: calc(100% - #{$sidebar-width});
  float: right;
  height: 100vh;
}

//main section
.main-section-no-data {
  height: calc(100% - 85px) !important;
  padding: 0 !important;
}
.main-section {
  width: 100%;
  float: left;
  height: calc(100% - 125px);
  background: #f4fdf9;
  padding: 15px;
  overflow: auto;
  .no-data {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: auto;
    padding-top: 25px;
    .add-project-btn {
      @extend %primary-btn;
      max-width: 180px;
      height: 40px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      margin: 30px 0;
    }
    .welcome-text {
      font-size: 34px;
      font-weight: bold;
      color: #01323e;
      margin: 20px 0;
      width: 100%;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 15px;
    }
  }
  .main-inner-card {
    background: #fff;
    box-shadow: 0px 1px 6px #0000001a;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    max-height: 60vh;
    padding: 15px;
    float: left;
    overflow: auto;
    flex-flow: wrap;

    .profile-box {
      max-width: 500px;
      margin: 0 auto;
      .profile-img {
        max-width: 130px;
        margin: 0 auto;
        margin-bottom: 30px;
        position: relative;
        img {
          width: 130px;
          height: 130px;
          object-fit: cover;
          border-radius: 15px;
        }
        .file-upload {
          position: relative;
          width: 34px;
          height: 34px;
          background: #edf7f3;
          border: 3px solid #ffffff;
          float: right;
          border-radius: 20px;
          margin-top: -24px;
          margin-right: -10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          input {
            height: 34px;
            width: 34px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
          }
          i {
            font-size: 10px;
            color: $primary-color;
          }
        }
      }
      .form-row {
        margin-bottom: 20px;
        width: 100%;
        float: left;
        .col-form-label {
          @extend %input-label;
        }
        .form-control {
          @extend %input-box;
          min-height: 60px;
          height: auto;
        }
      }
      .profile-btn {
        display: flex;
        margin-top: 30px;
        width: 100%;
        float: left;
        .primary-btn {
          @extend %primary-btn;
        }
        .cancel-btn {
          @extend %primary-btn;
          background: #2f4d422b;
          color: $gray;
          border: none;
          margin-right: 20px;

          &:hover {
            background: #2f4d422b;
            color: #06070b;
          }
        }
      }

      .profile-details {
        width: 100%;
        float: left;
        text-align: center;
        h2 {
          font-size: 30px;
          font-weight: 800;
          color: $primary-color;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        p {
          font-size: 20px;
          font-weight: bold;
          color: #01323e;
          margin-bottom: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .add-user-box {
      width: 100%;
      float: left;
      margin-bottom: 30px;
      margin-top: 5px;
      .add-user-box-text {
        font-size: $fontsize-18;
        color: $gray;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 22px;
        text-align: center;
      }
    }
    .cust_checkbox {
      @extend %cust_checkbox;
      margin-right: 28px;
    }
  }
  .add-alarm {
    padding: 30px 0;
    .profile-box {
      .profile-btn {
        .primary-btn {
          max-width: 240px;
        }
      }
    }
  }

  //about-section
  .about-section {
    width: 100%;
    float: left;
    .about-section-row {
      width: 100%;
      float: left;
      .about-section-title {
        font-size: $fontsize-18;
        line-height: 22px;
        font-weight: bold;
        color: $primary-color;
        text-transform: capitalize;
      }
      .about-section-text {
        font-size: $fontsize-14;
        font-weight: 500;
        color: $gray;
        line-height: 18px;
        margin-bottom: 15px;
      }
    }
  }

  //faq-section
  .faq-section {
    .accordion-flush {
      .accordion-item {
        background: #edf7f3;
        border-radius: 6px;
        border: none;
        box-shadow: 0px 0px 8px #5555551a;
        margin-bottom: 15px;
        .accordion-button {
          border: none;
          padding: 15px;
          border-radius: 6px;
          font-weight: bold;
          font-size: $fontsize-18;
          line-height: 22px;
          color: $primary-color;
          box-shadow: none;
          &:not(.collapsed) {
            background-color: #edf7f3;
            padding-bottom: 0;
            &::after {
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231D946B' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
            }
          }
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231D946B' class='bi bi-caret-down-fill' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
          }
        }
        .accordion-body {
          font-size: 12px;
          line-height: 15px;
          font-weight: 600;
          color: #06070b;
        }
      }
    }
  }

  //Add Project
  .add-projects {
    .form-row {
      width: 100%;
      float: left;
      margin-bottom: 30px;
      .col-form-label {
        @extend %input-label;
      }
      .form-control {
        @extend %input-box;
        min-height: 60px;
        height: auto;
      }
      .form-select {
        @extend %select;
      } //logic edit
      .cust_radio {
        @extend %cust_radio;
      }
      .tagify {
        height: 59px;
        background: #1d946b0f;
        display: flex;
        align-items: center;
        border: none;
        border-radius: 5px;
        padding: 10px;
        flex-flow: row;
        overflow-x: auto;
        overflow-y: hidden;
        .tagify__tag {
          border-radius: 6px;
          margin: 0 10px 0 0;
          height: 39px;
          padding: 0 10px;
          background: #fff;
          div {
            font-size: 12px;
            color: $primary-color;
            font-weight: 600;
            &::before {
              box-shadow: 0 0 0 var(--tag-inset-shadow-size, 1.1em) #fff inset !important;
              border-radius: 6px !important;
            }
            * {
              white-space: nowrap;
            }
          }
          .tagify__tag__removeBtn {
            width: 20px;
            height: 20px;
            margin: 0;
            &::after {
              display: inline-block;
              content: "";
              vertical-align: -0.125em;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-x' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-size: 1rem 1rem;
              width: 15px;
              height: 15px;
            }
            &:hover {
              background: #fff !important;
            }
          }
        }
        .tagify__input {
          font-size: $fontsize-16;
          font-weight: 600;
          text-transform: capitalize;
        }
      }
      .child{
        padding-left: 25px;
      }
      .subChild{
        padding-left: 50px;
      }
    }
    .profile-btn {
      text-align: center;
      width: 100%;
      float: left;
      margin: 30px 0;
      .primary-btn {
        @extend %primary-btn;
        max-width: 240px;
      }
      .cancel-btn {
        @extend %primary-btn;
        background: #2f4d422b;
        color: $gray;
        border: none;
        margin-right: 20px;
        max-width: 240px;
        &:hover {
          background: #2f4d422b;
          color: #06070b;
        }
      }
    }
    .customSelect{
      list-style: none;
      position:fixed;
      z-index: 999;
      background-color: white;
      min-width: 400px;
      max-height: 400px;
      overflow:auto;
      padding: 20px;
      /* border: 1px solid; */
      box-shadow: 0px 3px 6px #00000029;
    li{
      padding: 10px;
      &:hover{
        background: #1D946B;
        color: white;
        
      }
     
    }
    }
    
  }
  .selectProject{
    background: #1D946B;
    color: white;
    
  }
  //Tab Menu
  .tab-menu-section {
    float: left;
    margin-top: -15px;
    width: calc(100% + 30px);
    height: 100%;
    margin-left: -15px;
    .nav-pills {
      background: #fff;
      padding: 0 15px;
      position: sticky;
      top: -15px;
      margin-bottom: 15px;
      .nav-item {
        .nav-link {
          color: #1d946b66;
          font-weight: 600;
          font-size: $fontsize-14;
          &.active {
            color: $primary-color;
            background-color: #fff;
            border-bottom: 3px solid $primary-color;
            border-radius: 0;
          }
        }
      }
    }
    .tab-content {
      padding: 0 15px 15px 15px;
      width: 100%;
      float: left;
      overflow: auto;
      height: calc(100vh - 180px);
      &.active {
        width: 100%;
        float: left;
      }
      .tab-pane {
        width: 100%;
        float: left;
      }
      .tab-project-details {
        .tab-project-details-row1 {
          .tab-project-details-row1-box {
            width: 100%;
            float: left;
            .tab-project-details-row1-box-img {
              width: 42px;
              height: 42px;
              float: left;
              background: #edf7f3;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .tab-project-details-row1-box-switch {
              float: left;
              margin-left: 15px;
              h4 {
                font-size: $fontsize-16;
                font-weight: 600;
                color: $gray;
                margin-bottom: 0;
              }
              .form-check {
                .form-check-input {
                  cursor: pointer;
                  &:checked {
                    background-color: #1d946b;
                    border-color: #1d946b;
                  }
                  &:focus {
                    box-shadow: none;
                  }
                }
              }
            }
          }
          .add-project-btn {
            @extend %primary-btn;
            height: 42px;
            max-width: 200px;
            font-size: 14px;
            font-weight: bold;
            border-radius: 6px;
            float: right;
          }
        }
        .tab-project-details-row2 {
          margin-top: 30px;
          width: 100%;
          float: left;
          .col-form-label {
            @extend %input-label;
            color: #2f4d42bf;
          }
          h6 {
            font-size: $fontsize-16;
            font-weight: 600;
            color: $gray;
            margin-bottom: 0;
          }
          ul {
            list-style: none;
            width: 100%;
            float: left;
            padding: 0;
            margin-bottom: 0;
            li {
              float: left;
              padding: 10px 15px;
              background: #1d946b17;
              font-size: $fontsize-16;
              font-weight: 600;
              line-height: 19px;
              color: $primary-color;
              border-radius: 4px;
              margin-right: 10px;
              margin-bottom: 10px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .edit-btn {
            @extend %primary-btn;
            max-width: 250px;
          }
          //ProjectDetails.js module use this btn-edit-template class
          .btn-edit-template {
            @extend %primary-btn;
            max-width: 250px;
            margin-left: 25px;
          }
          .btn-danger {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
            border-color: #dc3545;
            margin-left: 25px;
            &:hover {
              color: #dc3545;
              background-color: #fff !important;
            }
          }
        }
      }
      .alarm-tab-section {
        padding: 0;

        table {
          @extend %table;
          position: relative;

          tbody {
            tr {
              td {
                min-width: 150px;
                input {
                  font-size: 12px;
                  font-weight: 600;
                  &:focus {
                    border-color: #1d946b;
                    box-shadow: 0 0 0 0.25rem #1d946b40;
                  }
                  &.error {
                    border-color: #dc3545 !important;
                    box-shadow: 0 0 0 0.25rem #dc35458c !important;
                  }
                }
                #alarmName,
                #description,
                #language {
                  margin-bottom: 0;
                  height: 35px;
                  padding: 5px;
                  border: 0.5px solid #ddd;
                  border-radius: 5px;
                  padding-right: 35px;
                  line-height: 22px;
                  overflow: hidden;
                }
                .export-doc-btn {
                  position: absolute;
                  right: 3px;
                  width: 30px;
                  height: 30px;
                  border-radius: 4px;
                  border: none;
                  background: #1d946b;
                  top: 2px;
                  color: #fff;
                  font-size: 16px;
                }
              }
              .add-alarm-del {
                width: 60px;
                min-width: 60px;
                cursor: pointer;
                text-align: center;
              }
            }
          }
        }
        .export-btn {
          @extend %primary-btn;
          max-width: 120px;
          font-size: 12px;
          height: 35px;
          font-weight: bold;
          border-radius: 4px;
          margin-right: 10px;
        }
       
      }
      .sticky-last-child {
        table {
          @extend %table;
          position: relative;

          tbody {
            tr {
              td {
                &:nth-child(2) {
                  min-width: 100px;
                  width: 100px;
                }
                &:last-child {
                  position: sticky;
                  right: 0px;
                  width: 30px;
                  max-width: 30px;
                  min-width: 30px;
                  text-align: center;
                  // top: 60px;
                  img {
                    width: 12px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
      .alarm-tab-section-title {
        width: 100%;
        float: left;
        padding: 0px 0px 15px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0px;
        background: #f4fdf9;
        z-index: 1;
        h4 {
          font-size: $fontsize-18;
          font-weight: bold;
          color: $primary-color;
          float: left;
          margin-bottom: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .alarm-tab-section-box {
        width: 50%;
        float: right;
        .alarm-tab-section-search {
          width: calc(100% - 130px);
          float: right;
          position: relative;
          input {
            @extend %input-box;
            height: 30px;
            width: 100%;
            box-shadow: 0px 0px 6px #0000001a;
            padding: 0 10px;
            font-size: 12px;
            font-weight: 600;
            background: #fff;
            color: #2f4d4259;
          }
          button {
            position: absolute;
            right: 0;
            width: 30px;
            height: 30px;
            border-radius: 4px;
            border: none;
            background: $primary-color;
          }
        }
        .primary-btn {
          @extend %primary-btn;
          max-width: 120px;
          font-size: 10px;
          height: 30px;
          font-weight: bold;
          border-radius: 4px;
          margin-left: 10px;
          float: right;
        }
      }

      .table-pagination {
        width: 100%;
        float: left;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        li {
          float: left;
          min-width: 18px;
          height: 18px;
          border-radius: 3px;
          box-shadow: 0px 0px 6px #0000000d;
          background: #ffffff;
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          a,
          button {
            // logic edit
            font-size: 10px;
            font-weight: 600;
            color: $primary-color;
            text-decoration: none;
            border: none;
            background: #fff;
          }
          &.active {
            background: $primary-color;
            a,
            button {
              color: #fff;
              background: $primary-color;
            }
          }
        }
      }
    }
  }
}

//Footer Section
.footer-section {
  width: 100%;
  float: right;
  height: 25px;
  position: sticky;
  bottom: 0;
  top: 100%;
  background: #ddeee7;
  font-size: 12px;
  font-weight: 600;
  color: $gray;
  padding-top: 5px;
  text-align: center;
}
.footerLink{
  text-decoration: none;
  color:$gray
}
//alarm loader
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #1d946b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.disable_btn{ 
  
  @extend %primary-btn;
  max-width: 120px;
  font-size: 12px;
  height: 35px;
  font-weight: bold;
  border-radius: 4px;
  margin-right: 10px;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.mergeLoader {
  background-color: #cccccc;
  color: #666666;
  border: 4px solid #757575;
  border-radius: 50%;
  border-top: 4px solid #505050;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
// merge and export
.mergeScroll{
  max-height: 60vh;
  overflow: scroll;
}